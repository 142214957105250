import React from "react"

const MaintenancePage = () => {
  return (
    <div>
      This site is down for maintenance. Apologies for any inconvenience.
      <p>
        You can contact me{" "}
        <a href="contact@slickbluedragon.co.uk">at this email.</a>
      </p>
    </div>
  )
}

export default MaintenancePage
